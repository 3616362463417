*, *::before, *::after {
  box-sizing: inherit;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#dp-app {
  width: 100%;
  height: 100%;

  .gutter-horizontal {
    cursor: ew-resize;
    background: #dddddd;
  }
}